import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Box, Button } from "theme-ui";
import ContactHero from "../components/ContactHero";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import ContactForm from "../components/ContactForm.js";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Contact Us" description="" mdxType="SEO" />
    <ContactHero mdxType="ContactHero" />
    <Box sx={{
      pt: [4, 5, 5],
      pb: [4, 5, 5],
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
Let's talk about how we can partner together on a new or existing project. Please <AniLink fade title="Book a meeting with us" duration={0.35} to={`/book-a-meeting/`} style={{
        textDecoration: "underline",
        color: "#CF1F25"
      }} mdxType="AniLink">
          arrange a call
          </AniLink> or complete the contact form below and we will get back to you soon.
      <ContactForm mdxType="ContactForm" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      